/* @file
 * Styles for the Schedule page.
 */
 .page-schedule {
  #content {
    // the show thumbs are supposed to be 100% width
    padding: 0;
  }
 }
.schedule-browser {
  header {
    padding: 0 1rem;
  }
  .date-container {
    @include media($breakpoint-min-medium) {
      @include span-columns(6.5);
    }
  }
  h1.day-of-week {
    margin-bottom: 0.2em;
    font-size: 12vw;
    @include media($breakpoint-min-mobile-wider) {
      font-size: 2.8rem;
    }
    @include media($breakpoint-min-medium) {
      font-size: 4.2rem;
    }
  }
  p.date {
    margin-top: 0;
    font-size: 1.3em;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    .ordinal {
      vertical-align: super;
      font-size: 0.6em;
    }
  }
  .controls-container {
    @include media($breakpoint-min-medium) {
      @include span-columns(5.5);
      padding: 2rem 0;
    }
    @media screen and (min-width: 784px) and (max-width: 1079px) {
      // fixing overlapping elements on tablet
      float: left;
      clear: both;
      padding-top: 0;
      width: 100%;
    }
    .date-select {
      float: left;
    }
    .timezone-container {
      clear: both;
      float: left;
      padding: 1em 0;
      @include media($breakpoint-min-medium) {
        clear: none;
        padding: 0;
      }
      button {
        @include select-button-alt();
        @include media($breakpoint-min-medium) {
          // make sure square
          min-width: 55px;
        }
      }
    }
    select {
      @include select-button-alt($pad: 0.8rem 1.25rem 0.8rem 0.8rem);
      // fix for FireFox because it adds extra height to the text element in a select node
      max-height: 62px;
      @-moz-document url-prefix() {
        padding: 0.5rem 1.25rem 1.1rem 0.8rem
      }
    }
  }
  .schedule-content {
    $padding-top-inside: 1rem;
    clear: both;
    margin-top: 1em;
    .episode {
      border-top: 1px solid #a0a0a0;
      margin: 0;
      overflow: hidden;
      padding: 1em 0 0;
      @include media($breakpoint-min-medium) {
        @include outer-container;
        padding: 0;
      }
      > div {
        padding: 0 1rem;
        &.show-image {
          margin-top: 1rem;
          padding: 0;
          @include media($breakpoint-min-medium) {
            margin-top: 0;
          }
        }
      }
      .episode-time {
        float: left;
        font-size: 2em;
        letter-spacing: 0.07em;
        @include media($breakpoint-min-medium) {
          @include span-columns(1.5);
          padding-top: $padding-top-inside;
        }
        .minute {
          margin-left: 0.05rem;
          position: relative;
          top: 0.12em;
          vertical-align: super;
          font-size: 0.7em;
        }
        .ampm {
          position: relative;
          top: 0.12em;
          vertical-align: super;
          font-size: 0.6em;
          color: #a0a0a0;
        }
      }
      .episode-info {
        @include media($breakpoint-min-medium) {
          @include span-columns(5);
          padding-top: $padding-top-inside;
        }
        .indicator {
          background-color: $color-highlight-second;
          float: left;
          font-size: 0.8em;
          padding: 0.2em 0.5em 0 0.7em;
          text-transform: uppercase;
          &.on-now {
            background-color: $color-highlight-main;
            color: $black;
            font-weight: bold;
          }
          &.empty {
            display: none;
          }
        }
        .show-title {
          clear: both;
          padding: 1rem 0 0;
          font-size: 10vw;
          font-weight: 100;
          line-height: 1.5rem;
          a {
            text-decoration: none;
            &:hover {
              color: #a1a1a1;
            }
          }
          @include media($breakpoint-min-mobile-wider) {
            font-size: 2rem;
            line-height: 2rem;
            margin: 0 0 1rem;
            padding: 0.5rem 0 0;
          }
        }
        .episode-title {
          @include media($breakpoint-min-medium) {
            font-size: 1rem;
            margin: 0 0 0.5rem;
          }
        }
        .episode-synopsis {
          @include media($breakpoint-min-medium) {
            font-size: 0.8rem;
            line-height: 1.1rem;
            padding-bottom: 0.8rem;
          }
        }
      }
      .show-image {
        float: right;
        width: 100%;
        @include media($breakpoint-min-medium) {
          @include span-columns(5.5);
        }
        img {
          width: 100%;
          float: right;
          height: auto;
          @include media($breakpoint-min-medium) {
          }
        }
      }
    }
  }
}