/**
 * Styles for the Photo List fieldable panels pane.
 */
.pane-bundle-photo-list {
  h2.pane-title {
    margin-top: 1.5em;
  }
  .pane-content {
    .fieldable-panels-pane {
      @include outer-container;
      .field-name-field-photo-list {
        @include media($breakpoint-min-medium) {
          @include span-columns(4);
        }
        .entity-photo  {
          .field-name-field-image {
            @include media($breakpoint-min-medium) {
              text-align: center;
            }
            img {
              max-width: 100%;
              height: auto;
            }
          }
          .field-name-field-image-caption {
            margin-bottom: 1em;
            @include media($breakpoint-min-medium) {
              text-align: center;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}