/* @file
 * Justice Network upcoming episodes block.
 */
.block-upcoming-episodes {
  margin-bottom: 2em;
  @include media($breakpoint-min-medium) {
    @include outer-container();
    .column-empty {
      @include span-columns(3);
    }
    .column-main {
      @include span-columns(9);
    }
  }
  .column-empty {
    height: 1px;
    margin: 0;
    padding: 0;
  }
  h3.title {
    font-family: $montserrat;
    font-weight: normal;
    font-size: 1.2em;
    letter-spacing: 0.2em;
  }
  .upcoming-episode {
    border-top: 1px solid #d0d0d0;
    &:last-child {
      border-bottom: 1px solid #d0d0d0;
    }
    .episode-date {
      padding: 1em 0 0.5em;
      font-family: $montserrat;
      font-weight: bold;
      text-transform: uppercase;
      color: $color-highlight-main;
      font-size: 2em;
      span.ordinal {
        vertical-align: super;
        font-size: 0.6em;
      }
      @include media($breakpoint-min-large) {
        float: left;
        width: 6em;
      }
    }
    .episode-details {
      padding: 0.5em 0 2em;
      display: table;
      @include media($breakpoint-min-large) {
        padding-top: 2em;
      }
      .episode-time {
        display: table-cell;
        width: 3.5em;
        font-size: 2.2em;
        border-right: 1px solid #d0d0d0;
        vertical-align: top;
        padding-top: 0.2em;
        @include media($breakpoint-min-large) {
          padding-left: 1em;
          border-right: none;
          border-left: 1px solid #d0d0d0;
        }
        span.minute {
          font-size: 0.7em;
          position: relative;
          top: -0.3em;
        }
        span.ampm {
          font-size: 0.7em;
          position: relative;
          top: -0.3em;
          left: 0.1em;
          color: #909090;
        }
      }
      .episode-info {
        display: table-cell;
        padding: 0 0 1em 1.5em;
        vertical-align: top;
        h4.episode-title {
          font-size: 0.9em;
          margin-top: 0;
        }
        .episode-synopsis {
          font-size: 0.9em;
        }
      }
    }
  }
}