/* @file
 * Justice Network header styles
 */

#header a.menu-toggle {
  display: none;
  @include media($breakpoint-mobile) {
    display: block;
    float: right;
    background-image: url(../images/nav-hamburger.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 2em;
    padding: 1.25em;
    margin-top: 0.3em;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}

#header-logo {
  display: block;
  margin: 0;
  @include media($breakpoint-min-medium) {
    display: table-cell;
    vertical-align: middle;
  }
  a:hover img {
    opacity: 0.85;
  }
  img {
    height: auto;
    max-width: 110px;
    @include media($breakpoint-min-medium) {
      max-width: 100%;
    }
    &.logo-white {
      display: none;
    }
    &.logo-default {
      display: block;
    }
  }
}
#region-header-wrapper {
  display: table-cell;
}
#header-wrapper {
  width: 100%;
  background-image: linear-gradient(rgba(46, 44, 45, 222), rgba(46, 44, 45, 0));
  padding: 1em 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  &.mobile-menu-open {
    background-color: $black;
    background-image: none;
    padding-top: 1.4rem;
    position: absolute;
    top: -1.1em;
    left: 0;
    #region-header-wrapper {
      display: block;
    }
    #header-logo {
      img.logo-default {
        display: none;
      }
      img.logo-white {
        display: block;
        width: 13em;
      }
    }
    a.menu-toggle {
      background-image: url(../images/nav-mobile-close.png);
    }
  }
}

@include media($breakpoint-mobile) {
  #region-header-wrapper {
    display: none;
  }
}

#region-header {
  display: flex;
  align-items: center;
  width: 100%;
  @include media($breakpoint-mobile) {
    display: block;
  }
  .nav-header {
    flex-grow: 1;
    text-align: right;
    ul.menu {
      text-align: inherit;
      padding: 0;
      margin: 0;
      li {
        color: white;
        display: inline;
        list-style: none;
        margin: .5em;
        a {
          color: white;
          text-decoration: none;
          text-transform: uppercase;
          font-size: .7em;
          font-weight: bold;
          &:hover {
            color: $text-hover-grey;
          }
        }
      }
      @include media($breakpoint-mobile) {
        text-align: left;
        margin-top: 2.5em;
        li {
          display: block;
          padding: 1.25em 0;
          margin: 0;
          border-bottom: 1px solid $color-highlight-main;
          a {
            font-size: 1.25em;
          }
        }
      }
    }
  }
  .icon-list-header {
    width: 4em;
    flex-grow: 0;
    @include media($breakpoint-mobile) {
      display: block;
      margin: 0;
      width: 100%;
      border-bottom: 1px solid $color-highlight-main;
    }
    .field-name-field-linked-icons {
      & > .field-items {
        display: flex;
        & > .field-item {
          padding: 0.5em;

          svg {
            stroke: white;
            fill: white;
            width: 1em;
            height: 1em;

            &:hover {
              stroke: $color-highlight-main;
              fill: $color-highlight-main;
            }
          }

          @include media($breakpoint-mobile) {
            padding: 1em 2.5em 0.75em 0;
            svg {
              width: 1.25em;
              height: 1.25em;

              &:hover {
                stroke: $text-hover-grey;
                fill: $text-hover-grey;
              }
            }
          }
        }
      }
    }
  }
  .header-find-justice {
    flex-grow: 0;
    flex-shrink: 0;
    text-align: right;
    width: 21em;
    a.watch-stream {
      font-size: .65em;
      font-weight: bold;
      text-decoration: none;
      text-transform: uppercase;
      display: inline-block;
      padding: 0.6em 1em;
      margin-right: 1em;
      background-color: $color-highlight-main;
      color: black;
      &:hover {
        text-decoration: underline;
      }
    }
    a.find-justice {
      font-size: .65em;
      font-weight: bold;
      text-decoration: none;
      text-transform: uppercase;
      display: inline-block;
      padding: 0.6em 3em 0.6em 1em;
      border: 1px solid white;
      background-image: url(../images/location-icon.png);
      background-position: right 1em center;
      background-repeat: no-repeat;
      &:hover {
        border-color: $color-highlight-main;
        background-color: $color-highlight-main;
      }
    }
    @include media($breakpoint-mobile) {
      display: block;
      width: 20em;
      max-width: 100%;
      padding-bottom: 1em;
      a {
        &.watch-stream {
          font-size: 1em;
          display: block;
          margin: 3em 0 1em;
          padding: 1em;
          text-align: center;
        }
        &.find-justice {
          font-size: 1em;
          padding: 1.25em 1.5em 1em 1.5em;
          margin: 1em 0;
          display: block;
          text-align: center;
          &:hover {
            border-color: $text-hover-grey;
            background-color: $text-hover-grey;
          }
        }
      }
    }
  }
}