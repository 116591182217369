/* @file
 * SASS variables for Justice Network theme.
 */

// Variables for Neat.
$grid-columns: 12;
$max-width: 79rem;
$max-interior-width: 46rem;

// Color palette.
$background-main: #000000;
$background-dark-grey: #272727;
$background-light-grey: #dddcdc;
$color-highlight-main: #ffd600;
$color-highlight-second: #03527c;
$white: #ffffff;
$black: #000000;
$text-dark: #2e2f2f;
$light-blue: #0097e9;
$dark-blue: #053150;
$text-hover-grey: #a1a1a1;

// Fonts.
$serif: "Georgia", "Times New Roman", serif;
$montserrat: "Montserrat", Helvetica, Arial, sans-serif;

// Master breakpoints (old - desktop first)
$breakpoint-large: new-breakpoint(max-width 65rem);
$breakpoint-medium: new-breakpoint(max-width 57rem);
$breakpoint-mobile: new-breakpoint(max-width 55rem);
$breakpoint-narrow: new-breakpoint(max-width 23rem);

// Master breakpoints
$breakpoint-min-mobile-wide: new-breakpoint(min-width 23rem);
$breakpoint-min-mobile-wider: new-breakpoint(min-width 26rem);
$breakpoint-min-medium: new-breakpoint(min-width 55rem);
$breakpoint-min-large: new-breakpoint(min-width 62rem);
$breakpoint-min-xlarge: new-breakpoint(min-width 73rem);
$breakpoint-min-full: new-breakpoint(min-width 84rem);
