/* @file
 * Style definitions for a Media page and News content pages.
 */

body.node-type-news {
  $width_left: 22.5%;
  $width_right: (100 - $width_left);
  h1 {
    font-size: 20px;
    @include media($breakpoint-min-medium) {
      margin-top: 3.5rem;
    }
    @include media($breakpoint-min-large) {
      font-size: 40px;
      max-width: 80%;
    }
  }
  .section-main {
    @include media($breakpoint-min-medium) {
      float: right;
      margin-bottom: 3rem;
      width: $width_right;
    }
    @include media($breakpoint-min-large) {
      > * {
        max-width: 80%;
      }
    }
    img {
      height: auto;
      max-width: 100%;
    }
    .video {
      @include video-responsive();
    }
  }
  .section-cta {
    margin-bottom: 3em;
    @include media($breakpoint-min-medium) {
      float: left;
      width: $width_left;
    }
  }
}

body.page-node-44 {
  #content {
    @include media($breakpoint-min-medium) {
      padding: 0 1rem;
    }
    @include media($breakpoint-min-large) {
      padding: 0 2rem;
    }
      @include media($breakpoint-min-full) {
        padding: 2rem 4rem 0;
    }
  }

  .panel-2col {
    @include outer-container();

    .panel-col-first {
      @include span-columns(12);
      @include media($breakpoint-min-medium) {
        @include span-columns(7);
      }
      @include media($breakpoint-min-large) {
        @include span-columns(8);
      }
    }
    .panel-col-last {
      @include span-columns(12);
      @include media($breakpoint-min-medium) {
        @include span-columns(5);
        padding-top: 3.2rem;
      }
      @include media($breakpoint-min-large) {
        @include span-columns(4);
        padding-top: 5.35rem;
      }

      .panel-separator {
        margin-bottom: 3em;
      }

      h2.pane-title {
        font-family: 'Helvetica Neue';
        font-size: 1.5 * $base-font-size-narrow;
        font-weight: 700;
        @include media($breakpoint-min-medium) {
          font-size: 1.1em;
        }
      }

      .document-link {
        margin-bottom: 0.5em;
        a {
          color: white;
          font-size: .9em;
          text-decoration: underline;
        }
        a:hover {
          color: #ffffff;
          text-decoration: none;
        }
      }

      .node-webform {
        header {
          display: none;
        }
        .webform-client-form {
          input.form-email {
            width: 100%;
          }
        }
      }
    }
  }

  .view-recent-news {

    .view-content {
      padding-bottom: 1em;
      margin-bottom: 1em;
      border-bottom: 1px solid #d0d0d0;
    }
    .views-row {
      font-size: 0.9em;
      padding-top: 1em;
      margin-top: 1em;
      border-top: 1px solid #d0d0d0;

      .views-field-title {
        a {
          font-size: 1.1em;
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
      }
      .views-field-body {
      }
      .views-field-view-node {
        a {
          @include button-default();
          margin-bottom: 1em;
        }
      }
    }
  }
}
