/* @file
 * Re-usable style elements for use throughout the Justice Network site.
  */
.pane-bundle-video div div {
  @include video-responsive();
}
.webform-component--required {
  em {
    color: red; // required asterisk, key for webform as custom markup
  }
}
a:hover {
  text-decoration: underline;
}
ul.pager-load-more {
  margin-top: 2em;
  a {
    @include button-default();
  }
}
