/* @file
 * Styles for the time zone selector/schedule page.
 */
.pane-jn-tz-manager-widget {
  .show-hide {
    display: none;
    .show-hide-button {
      display: inline-block;
      padding: 0 1em;
      margin: 0;
      font-family: $montserrat;
      font-weight: 700;
      font-size: 0.5rem;
      letter-spacing: 0.1em;
      line-height: 2;
      text-transform: uppercase;
      background-color: #363636;
      position: relative;
      top: 1px;
      @include media($breakpoint-min-xlarge) {
        top: 3px;
      }
      @include media($breakpoint-min-medium) {
        top: 2px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  @include outer-container();
  margin-left: -1rem;
  margin-right: -1rem;
  max-height: 99px; // iOS fix, JN-41
  @include media($breakpoint-min-mobile-wide) {
    margin-left: -2rem;
    margin-right: -2rem;
    max-height: 124px; // iOS fix, JN-41
  }
  .pane-content {
    @include fill-parent();
    @include row(table);
    .form-item-tzSelect {
      @include span-columns(4);
      background-color: #828282;
      padding: 0.5rem 0.5rem 0.5rem 1rem;
      vertical-align: middle;
      @include media($breakpoint-min-medium) {
        @include span-columns(2);
        padding-left: 2rem;
      }
    }
    .jn-tz-schedule-widget {
      @include span-columns(8);
      padding: 0.5rem 1rem;
      @include media($breakpoint-min-medium) {
        @include span-columns(10);
        padding-left: 2rem;
        padding-right: 2rem;
      }
      background-color: white;
      color: #505050;
      .jn-tz-episode-wrapper {
        a {
          color: inherit;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        .time {
          font-size: 0.8em;
          font-weight: 300;
        }
        .show-title {
          font-size: 0.9em;
          letter-spacing: 0;
          font-family: $montserrat;
          text-transform: uppercase;
        }
        .jn-tz-indicator-wrapper {
          float: left;
          height: 4.5em;
          margin: 0 0.5em;
          .indicator {
            display: inline-block;
            font-size: 0.5em;
            font-family: $montserrat;
            font-weight: bold;
            letter-spacing: 0;
            color: white;
            text-transform: uppercase;
            background-color: rgb(3, 82, 124);
            min-width: 5em;
            text-align: center;
            &.current {
              color: black;
              background-color: $color-highlight-main;
            }
            &.next {
              background-color: $color-highlight-second;
            }
            @include media($breakpoint-min-medium) {
              padding: 0 0.8em 0 1em;
              min-width: 7em;
            }
          }
        }
        .jn-tz-episode {

        }
      }
      .slick-prev, .slick-next {
        &:before {
          color: #808080;
        }
        top: 60%;
      }
      .slick-prev {
        left: -18px;
      }
      .slick-next {
        right: -3em;
        @include media($breakpoint-min-mobile-wide) {
          right: -1em;
        }
        @include media($breakpoint-min-large) {
          right: 0;
        }
      }
      .slick-slider {
        margin-bottom: 0;
      }
    }
  }
  &.sticky {
    position: fixed;
    bottom: 0;
    z-index: 10;
    width: 100%;
    max-width: 1360px;
    .show-hide {
      display: block;
    }
  }
}