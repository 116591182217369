/* @file
 * Styles for the On Now block/pane.
 */
.pane-wideorbit-schedule-manager-on-now {
  margin: 0 -1rem;
  background-color: $background-light-grey;
  color: #303030;
  @include media($breakpoint-min-mobile-wide) {
    margin: 0 -2rem;
  }
  .pane-content {
    .episode-on-now {
      .episode-info {
        padding: 1rem;
        @include media($breakpoint-min-medium) {
          display: inline-block;
          width: 34%;
          vertical-align: top;
          padding-left: 2rem;
        }
        h3.show-title {
          font-size: 2em;
        }
        h4.episode-title {
          font-size: 1em;
          letter-spacing: 0.05em;
        }
        .episode-synopsis {
          margin-bottom: 1em;
          @include media($breakpoint-min-medium) {
            font-size: 0.8em;
            letter-spacing: 0.05em;
          }
        }
        .show-link {
          margin-bottom: 1em;
          .button {
            font-size: 1em;
            @include media($breakpoint-min-medium) {
              font-size: 0.7em;
            }
          }
        }
        .indicator {
          display: inline-block;
          font-size: 0.7em;
          font-family: $montserrat;
          font-weight: bold;
          letter-spacing: 0;
          color: $black;
          text-transform: uppercase;
          background-color: $color-highlight-main;
          min-width: 5em;
          text-align: center;
          @include media($breakpoint-min-medium) {
            padding: 0 0.8em 0 1em;
            min-width: 7em;
          }
        }
      }
      .show-image {
        overflow: hidden;
        @include media($breakpoint-min-medium) {
          display: inline-block;
          width: 65%;
          float: right;
        }
        img {
          display: block;
          width: 100%;
          height: auto;
          @include media($breakpoint-min-medium) {
            float: right;
            width: auto;
            height: 100%;
          }
        }
      }
    }
  }
}