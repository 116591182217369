/* @file
 * Style definitions for a Podcast page.
 */
.page-node.node-type-podcast {
  .content {
    @include media($breakpoint-min-medium) {
      @include outer-container();
      .section-cta {
        @include span-columns(3);
      }
      .section-main {
        @include span-columns(9);
      }
    }

    .playlist {
      margin-bottom: 1rem;
    }
  }
}

