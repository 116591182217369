/**
 * Styles for the Video List fieldable panels pane.
 */
.pane-bundle-video-list {
  h2.pane-title {
    margin-top: 1.5em;
  }
  .field-name-field-video-list {
    margin-bottom: 2em;
    .entity-video {
      .content {
        @include outer-container;
        .video {
          @include media($breakpoint-min-medium) {
            @include span-columns(5);
          }
          iframe {
            max-width: 100%;
            width: 300px;
            height: 150px;
            @include media($breakpoint-min-medium) {
              width: 400px;
              height:200px;
            }
            @include media($breakpoint-min-large) {
              width: 500px;
              height:250px;
            }
          }
        }
        .field-name-field-video-embed {
          max-width: 100%;
          font-size: 0.8em;
          @include media($breakpoint-min-mobile-wide) {
            font-size: inherit;
          }
          @include media($breakpoint-min-medium) {
            @include span-columns(7);
          }
        }
      }
    }
  }
}