/* @file
 * Layout sass file for Justice Network.
 */

body {
  text-align: center;
}

#skip {
  display: none;
}

#page {
  text-align: left;
  width: 100%;
  margin: 0;
  overflow: hidden; // fix left-right scrolling on mobile
  padding: 0;
  position: relative;
}

#main {
  margin: 0 auto;
  max-width: 1360px;
  padding: 0;
  .tabs {
    position: absolute;
    top: 0.3em;
    z-index: 20;
  }
}

#content {
  margin: 0;
  padding: 0 1rem;
  @include media($breakpoint-min-mobile-wide) {
    padding: 0 2rem;
  }
}

.container-wrapper {
  margin: 0 auto;
  max-width: 1360px;
  padding: 0 2.5em;
  @include media($breakpoint-narrow) {
    padding: 0 1.75em;
  }
}

.footer-wrapper {
  margin: 0 auto;
  max-width: 1360px;
  padding: 0;
}

#header {
  position:relative;
  display: table;
  width: 100%;
  @include media($breakpoint-mobile) {
    display: block;
  }
}

// Two-column panel layout
.panel-2col {
  @include outer-container;
  .panel-col-first {
    @include span-columns(12);
    @include media($breakpoint-min-medium) {
      @include span-columns(7);
    }
  }
  .panel-col-last {
    @include span-columns(12);
    @include media($breakpoint-min-medium) {
      @include span-columns(5);
      @include omega();
      .inside {
        margin-left: 0.5em;
      }
    }
    .inside {
      margin-left: 0;
    }
  }
}