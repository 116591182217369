/* @file
 * Style definitions for a Show profile page.
 */
.page-node.node-type-show {
  .content {
    @include media($breakpoint-min-medium) {
      @include outer-container();
      .section-cta {
        @include span-columns(3);
      }
      .section-main {
        @include span-columns(9);
      }
    }

    .video {
      @include video-responsive();
    }
  }
}

#social-share {
  width: 12em;
  background-color: $color-highlight-main;
  color: black;
  font-weight: bold;
  @include media($breakpoint-min-medium) {
    margin-top: 2em;
  }
  @include media($breakpoint-min-xlarge) {
    margin-top: 1.8rem;
  }
}

#share-dialog {
  position: relative;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
  background-color: white;
  color: #0b0b0b;
  width: 90%;
  @include media($breakpoint-min-medium) {
    font-size: 0.8em;
  }
  @include media(new-breakpoint(min-width 26rem)) {
    width: 20rem;
  }
  .mfp-close {
    font-size: 0.8em;
    &:hover {
      cursor: pointer;
    }
  }
  .share-title {
    color: $color-highlight-main;
    font-family: $montserrat;
    text-transform: uppercase;
  }
  .a2a_kit {
    display: inline-block;
  }
}

