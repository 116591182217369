/* @file
 * Style definitions for About page for Justice Network.
 */

.page-node-6 {
  @include alt-page-bg($pane-pad: 0 2.3rem);
  @include media($breakpoint-min-medium) {
    #page {
      text-align: left;
    }
  }
  #content img { // ensure images are responsive by default
    height: auto;
    max-width: 100%;
  }
  .see-more-arrow {
    display: none; // hide for now
  }
  .pane-title {
    font-weight: 100;
  }
  .pane-intro-text {
    margin: auto;
    max-width: 49rem;
    text-transform: uppercase;
    @include media($breakpoint-min-medium) {
      text-align: center;
    }
    .pane-title {
      @include header-alt-title($m: 2.5rem 0 1rem);
    }
    .pane-content {
      @include header-alt-content($pad: 0 1rem 1.5rem);
    }
  }
  .pane-featured-bio {
    background-color: $background-main;
    color: white;
    overflow: hidden;
    text-transform: uppercase;
    .pane-content {
      border-bottom: 1px solid white;
      overflow: hidden;
      padding-bottom: 1rem;
      @include media($breakpoint-min-medium) {
        min-height: 950px;
        padding: 0 0 0.5rem;
        position: relative;
      }
    }
    .field-name-field-name {
      @include section-alt-header($pad: 2rem);
      @include header-alt-title($m: 0 0 1rem);
      line-height: 1.4rem;
    }
    .field-name-field-preamble-text {
      @include header-alt-content();
      p {
        margin: 0 auto;
      }
      @include media($breakpoint-min-medium) {
        line-height: 1.35rem;
        padding: 0;
        text-align: left;
        width: 40%;
      }
    }
    .field-name-field-bio {
      @include header-alt-content($pad: 1rem, $bg: white, $color: black, $fs: 0.6rem, $lh: 1rem, $ta: left);
      clear: both;
      text-transform: none;
      @include media($breakpoint-min-medium) {
        float: left;
        margin-top: 1rem;
        padding: 2.7rem 4rem;
        position: relative;
        width: 53%;
        z-index: 2;
      }
    }
    .field-name-field-bio-image {
      float: right;
      width: 100%;
      @include media($breakpoint-min-medium) {
        position: absolute;
        right: 0;
        top: 2rem;
        width: 51%;
        z-index: 1;
      }
      img {
        float: right;
        width: 100%;
        @include media($breakpoint-min-medium) {
          // float: none;
        }
      }
    }
  }
  .pane-bio-list {
    background-color: $background-main;
    color: white;
    .pane-title {
      @include section-alt-header($fs_lh: 1.3rem, $fw: 100);
      margin-bottom: 1rem;
      @include media($breakpoint-min-medium) {
        padding-top: 3rem;
      }
    }
    .field-name-field-bios > .field-items > .field-item {
      border-bottom: 1px solid white;
      margin-bottom: 1rem;
      @include media($breakpoint-min-medium) {
        margin-bottom: 0;

      }
      &:last-child {
        border-bottom: none;
      }
    }
    .entity-bio {
      $left: 32%;
      $right: (100 - $left);
      @include media($breakpoint-min-medium) {
        padding: 1.5rem 0 1rem;
      }
      > h2 {
        display: none;
      }
      .bio-image-text {
        display: inline-block;
        position: relative;
        @include media($breakpoint-min-medium) {
          float: left;
          width: $left;
        }
      }
      .bio-text-wrap { // to become the new wrapper, JN-59
        position: absolute;
        bottom: 1.5rem;
        left: 1rem
      }
      .field-name-field-name {
        @include header-alt-profession($clr: $color-highlight-main, $clear: left);
        margin-bottom: 0.2rem;
      }
      .field-name-field-professional-title {
        @include header-alt-profession();
      }
      .field-name-field-bio {
        text-align: left;
        @include media($breakpoint-min-medium) {
          float: right;
          padding-left: 1rem;
          width: $right;
        }
        p {
          @include media($breakpoint-min-medium) {
            margin: 0 0 2rem;
          }
        }
      }
      .field-name-field-bio-image {
        @include media($breakpoint-min-medium) {
        }
      }
    }
  }
}
