/* @file
 * Styles for webform panes.
 */
.pane-webform {
  input {
    max-width: 100%;
  }
}
.pane-webform-contact {
  padding: 1em;
  background-color: black;
  .webform-component {
    input, textarea {
      background-color: transparent;
    }
  }
}
.pane-contact-title h1 {
  margin-top: 0;
}