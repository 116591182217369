/* @file
 * Styles for Statistics Pair bean for Justice Network.
 */
$breakpoint-stats-1row: new-breakpoint(min-width 41em);

.bean-statistics-pair {
  width: 100%;
  font-family: $montserrat;
  text-transform: uppercase;
  @include outer-container();
  .content {
    @include media($breakpoint-stats-1row) {
      @include fill-parent();
      @include row(table);
      height: 5.5em;
    }
    .statistic {
      height: 6.5em;
      @include media($breakpoint-stats-1row) {
        @include span-columns(6);
        vertical-align: middle;
        height: auto;
      }
      .statistic-inner {
        height: 4.5em;
        display: table;
        width: 100%;
        .number {
          display: table-cell;
          color: $color-highlight-main;
          width: 35%;
          height: 2.5em;
          padding: 0.2em 0 0;
          font-size: 2.5em;
          letter-spacing: 0;
          text-align: right;
          background-image: url(../images/statistic-shield.png);
          background-repeat: no-repeat;
          background-position: right 0.5em center;
          background-size: 55px auto;
          @include media($breakpoint-min-medium) {
            background-size: 48px auto;
            font-size: 1.75em;
            padding: 0;
          }
          @include media($breakpoint-min-large) {
            background-size: 55px auto;
          }
          @include media($breakpoint-min-full) {
            background-size: 68px auto;
          }
          .field-items {
            margin-top: 0.85em;
            margin-left: auto;
            margin-right: 0.5em;
            width: 1.9em;
            text-align: center;
          }
        }
        .text {
          width: 65%;
          display: table-cell;
          vertical-align: middle;
          .field-name-field-stat-1-line-1, .field-name-field-stat-2-line-1 {
            font-size: 0.9em;
            margin-bottom: 0.3em;
            margin-left: 0.1em;
            @include media($breakpoint-min-medium) {
              font-size: 0.72em;
            }
          }
          .field-name-field-stat-1-line-2, .field-name-field-stat-2-line-2 {
            font-size: 2.2em;
            @include media($breakpoint-min-medium) {
              font-size: 2.25em;
            }
          }
        }
      }
    }
  }
  .statistic-1 {
    background-image: url(../images/stat-1-bg.png);
  }
  .statistic-2 {
    background-image: url(../images/stat-2-bg.png);
  }
}