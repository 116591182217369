/* @file
 * Justice Network hero styles.
 */
.hero-container {
  position: relative;
  min-height: 5rem;
  overflow: hidden;
  @include media($breakpoint-min-medium) {
    min-height: 7rem;
  }
  h1 {
    font-size: 32px;
    @include media($breakpoint-min-medium) {
      font-size: 56px;
    }
    @include media($breakpoint-min-large) {
      font-size: 72px;
    }
    @include media($breakpoint-min-xlarge) {
      font-size: 88px;
    }
    @include media($breakpoint-min-full) {
      font-size: 102px;
    }
  }
  .field-type-image {
    .field-item {
      text-align: center;
      position: relative;
      height: 15rem;
      @include media(new-breakpoint(min-width 28rem)) {
        height: 23rem;
      }
      @include media($breakpoint-min-medium) {
        height: 40.75rem;
      }
      img {
        display: block;
        position: absolute;
        left: -9999px;
        right: -9999px;
        margin: 0 auto;
        height: 13.5rem;
        width: auto;
        @include media(new-breakpoint(min-width 28rem)) {
          height: 25rem;
        }
        @include media($breakpoint-min-medium) {
          height: 40.75rem;
        }
      }
    }
  }
  .hero-gradient {
    position: absolute;
    bottom: 1.5rem;
    left: 0;
    width: 100%;
    height: 8rem;
    @include media(new-breakpoint(min-width 28rem)) {
      bottom: 0;
      height: 16rem;
    }
    @include media($breakpoint-min-medium) {
      height: 25rem;
    }
    @include background-image(linear-gradient(transparent 0, rgba(0, 0, 0, 0.6) 50%, rgba(0, 0, 0, 1.0) 90%));
  }
  .hero-title {
    position: absolute;
    font-weight: bold;
    left: 1rem;
    max-width: 80%;
    top: 6rem;
    @include media($breakpoint-min-mobile-wide) {
      left: 2rem;
    }
    @include media(new-breakpoint(min-width 28rem)) {
      top: 8rem;
    }
    @include media($breakpoint-min-medium) {
      top: 22rem;
    }

  }
  &.collapsed {
    display: none;
    .hero-title {
      display: none;
    }
  }
}