/* @file
 * Style definitions for Partners page for Justice Network.
 */

.page-node-5 {
  @include alt-page-bg();
  .pane-title {
    font-weight: 100;
    @include media($breakpoint-min-medium) {
      margin-top: 2rem;
    }
  }
  .pane-bundle-icon-text-list {
    margin: 0 auto;
    max-width: $max-interior-width;
    overflow: hidden;
    .field-type-entityreference > .field-items > .field-item {
      font-weight: 600;
      @include media($breakpoint-min-medium) {
        float: left;
        width: (100% / 3);
        .field-type-image,
        .field-type-text-long {
          clear: both;
          display: block;
        }
        .field-type-text-long {
          margin: 0 auto 2rem;
          width: 80%;
        }
      }
    }
  }
  .pane-bundle-video {
    margin: 1rem auto 0;
    max-width: $max-interior-width;
    position: relative;
    z-index: 1;
  }
  .pane-bundle-email {
    background-color: $dark-blue;
    color: white;
    position: relative;
    padding: 1rem;
    text-transform: uppercase;
    z-index: 2;
    @include media($breakpoint-min-medium) {
      padding-top: 2.5rem;
      p {
        font-size: 0.8rem;
      }
    }
    h3 {
      font-size: 1.4rem;
      font-weight: 100;
      @include media($breakpoint-min-medium) {
        display: inline;
        + p {
          display: inline;
        }
      }
    }
    a {
      text-decoration: none;
      word-wrap: break-word;
    }
    .email-info {
      color: $color-highlight-main;
      font-size: 1.4rem;
      line-height: 1.5rem;
    }
  }
  .pane-webform {
    background: $background-main;
    color: white;
    display: inline-block;
    padding: 2rem 0 1.5rem;
    width: 100%;
    @include media($breakpoint-min-full) {
      background-image: url('../images/bg-get-in-touch.png');
      background-repeat: no-repeat;
      background-position: right -60px;
      clear: both;
      float: left;
    }
    h2 {
      @include media($breakpoint-min-medium) {
        margin-bottom: 0;
      }
    }
    a {
      pointer-events: none;
      text-decoration: none;
    }
    form {
      margin: 0 auto;
      max-width: 35rem;
      padding: 0 1rem;
    }
    input[type=text], input[type=password], input[type=number], input[type=email], textarea {
      background-color: $background-dark-grey;
      margin-bottom: 1rem;
      width: 100%;
    }
    label {
      font-weight: 100;
      text-align: left;
      @include media($breakpoint-min-medium) {
        font-size: 0.75rem;
      }
    }
    .form-item, .form-actions {
      background-color: $background-dark-grey;
      clear: both;
      display: inline-block;
      margin: -1px 0;
      padding: 1px 1rem;
      width: 100%;
    }
    .form-item.webform-component--first-name {
      padding-top: 1rem;
      @include media($breakpoint-min-medium) {
        clear: none;
        float: left;
        width: 50%;
      }
    }
    .webform-component--last-name {
      @include media($breakpoint-min-medium) {
        clear: none;
        float: left;
        padding-top: 1rem;
        width: 50%;
      }
    }
    .form-actions{
      padding-bottom: 2rem;
      input {
        font-size: 1.1em;
        font-weight: 100;
        padding: 0.3rem 1rem;
        @include media($breakpoint-min-medium) {
          font-size: 0.75rem;
        }
      }
    }
    .webform-component-markup {
      text-align: left;
      &:first-of-type { // pre-amble text prior to form, after title
        background: transparent;
        margin: 0 auto;
        max-width: 26rem;
        text-align: center;
        text-transform: uppercase;
        @include media($breakpoint-min-medium) {
          font-size: 0.8rem;
          line-height: 1rem;
        }
      }
      &.webform-component--required {
        @include media($breakpoint-min-medium) {
          float: left;
        }
        p {
          margin: 0 0 1.5rem;
          @include media($breakpoint-min-medium) {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
  .statistic {
    .text .field-item {
      color: white;
    }
  }
}
