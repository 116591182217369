@mixin button-default() {
  display: inline-block;
  font-family: $montserrat;
  font-size: 1em;
  font-weight: bold;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  background-color: $color-highlight-main;
  color: $black;
  border: none;
  padding: 0.8em 1.2em;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  @include media($breakpoint-min-medium) {
    font-size: 0.7em;
  }
}

@mixin alt-page-bg($bg: white, $color: $black, $pane-pad: 0 1rem, $of: hidden) {
  background-color: $bg;
  color: $color;
  .carousel-slide {
    color: white;
  }
  .hero-container {
    min-height: 0;
  }
  .hero-title {
    max-width: 30rem;
    @include media($breakpoint-min-medium) {
      top: 12rem;
    }
  }
  h2 {
    color: $color-highlight-main;
  }
  .hero-title {
    color: white;
    text-align: left;
  }
  #page {
    text-align: center;
  }
  #content {
    padding: 0;
  }
  #footer {
    color: white;
    text-align: left;
  }
  .panel-pane {
    overflow: $of;
    padding: $pane-pad;
  }
}

@mixin video-responsive($mb: -12%) {
  position: relative;
  &:before {
    display: block;
    content: "";
    padding-top: (475 / 640) * 100%;
    width: 100%;
    @include media($breakpoint-min-medium) {
      margin-bottom: $mb;
    }
  }
  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: (475 / 640) * 100%;
  }
}

@mixin select-button-alt($pad: 0.8rem) {
  border: 1px solid #a0a0a0;
  background-color: transparent;
  padding: $pad;
  margin: 0 0.5rem 0 0;
  &.selected {
    border-color: $color-highlight-main;
    background-color: $color-highlight-main;
  }
  &:hover {
    border-color: $color-highlight-main;
    background-color: $color-highlight-main;
  }
}

@mixin section-alt-header($clr: white, $fs_lh: 2.2rem, $fw: bold, $pad: 1rem, $mar: 0) {
  color: $clr;
  font-size: $fs_lh;
  font-weight: $fw;
  line-height: $fs_lh;
  padding-top: $pad;
  margin: $mar;
};
@mixin header-alt-content($pad: 0 1rem 1rem, $bg: transparent, $color: inherit, $fs: 0.7rem, $ta: center, $lh: 1.2rem) {
  background-color: $bg;
  color: $color;
  font-size: $fs;
  line-height: $lh;
  padding: $pad;
  text-align: $ta;
}
@mixin header-alt-title($fs: 1.2rem, $m: 1rem 0) {
  font-size: $fs;
  margin: $m;
}
@mixin header-alt-profession($clr: white, $clear: none, $fs: 0.7rem) {
  color: $clr;
  font-family: $montserrat;
  font-size: $fs;
  font-weight: 100;
  line-height: $fs;
  text-align: left;
  text-transform: uppercase;
  @include media($breakpoint-min-medium) {
    clear: $clear;
  }
}

@mixin simple-grid($rows: 3, $margin_right: 1.5%) {
  float: left;
  margin-right: $margin_right;
  width: ((100% / $rows) - ($margin_right * (($rows - 1) / $rows)));
  &:nth-child(#{$rows}n+#{$rows}){
    margin-right: 0;
  }
}
