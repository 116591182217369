/**
 * Show List Panel and Pane Styling
 *
 * Start off with all styles here...I'd like to break up after I get a grip on what I have, structure wise.
 *
 * I don't like the "show-page" class because it requires the panelizer settings to include that configuration.
 *
 */

$breakpoint-min-mobile-shows: new-breakpoint(min-width 26rem);


@mixin omega-reset($nth, $context: null) {
  @if $context == null {
    &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  } @else {
    &:nth-child(#{$nth}) { margin-right: flex-gutter($context); }
  }
  &:nth-child(#{$nth}+1) { clear: none; }
}

.pane-bundle-show-list {
  @include outer-container();
  margin-bottom: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid #aaaaaa;

  body.front & {
    background-color: #ffffff;
    border-bottom: none;
    margin: 0 -2rem;
    padding: 1em 4em 2em;

    h2 {
      color: #000000;
    }
  }

  .pane-content {
    .field-items {
      overflow: auto;

      .field-item {
        @include span-columns(12);
        @include omega(1n);
        margin-bottom: 1em;

        @include media($breakpoint-min-mobile-shows) {
          @include span-columns(6);
          @include omega-reset(1n);
          @include omega(2n);
        }
        @include media($breakpoint-min-medium) {
          @include span-columns(4);
          @include omega-reset(2n);
          @include omega(3n);
        }
        @include media($breakpoint-min-large) {
        }
        @include media($breakpoint-min-xlarge) {
        }
        @include media($breakpoint-min-full) {
        }

        a.show-link {
          display: block;
          text-decoration: none;

          .thumbnail {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;

            .field-name-field-image-grid {
              img {
                display: block;
                width: 100%;
                height: auto;
              }
            }

            .thumbnail-overlay {
              padding: .5em 1.75em;
              position: absolute;
              top: 0;
              width: 100%;
              height: 100%;
              overflow: hidden;
              opacity: 0;
              font-size: .65em;
              font-weight: normal;
              line-height: 1.25em;
              background-color: #000000;
              -webkit-transition: opacity .25s ease;
              -moz-transition: opacity .25s ease;

              .overlay-inner {
                position: relative;
                height: 85%;

                .field-name-field-show-airtime, .info {
                  position: absolute;
                  bottom: 0;
                }
                .field-name-field-show-airtime {
                  color: #2b81af;
                  font-style: italic;
                }
                .info {
                  right: 0;
                  background-color: #ffffff;
                  color: #000000;
                  font-size: 1.2em;
                  font-weight: 700;
                  font-style: italic;
                  padding: 9px 12px 4px;
                  -webkit-border-radius: 21px;
                  -moz-border-radius: 21px;
                  border-radius: 21px;
                }
              }
            }
          }

          &:hover {
            .thumbnail {
              .thumbnail-overlay {
                opacity: .8;
              }
            }
          }

        }
      }

    }
    .field-name-field-shows-link {
      display: table;
      margin-left: auto;
      margin-right: auto;

      a {
        @include button-default();
      }
    }
  }
}
