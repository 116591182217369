/* @file
 * Styles for the Find True Crime Network page, views, and blocks.
 */

// Find True Crime Network page.
.page-find-true-crime-network {
  h1.title {
    font-size: 2em;
  }
  .views-exposed-form {
    .views-widget-filter-field_geofield_distance {
      padding: .5em 0 0 0;
      .geofield-proximity-origin {
        width: 15em;
      }
    }
    .views-exposed-widget .form-submit {
      margin-top: 1.9em;
      font-weight: bold;
      color: $black;
      background-color: $color-highlight-main;
    }
  }
  .view-display-id-attachment_1 {
    border-top: 1px solid #a0a0a0;
    border-bottom: 1px solid #a0a0a0;
    margin: 3em 0;
    .views-field-title {
      font-size: 1.2em;
      font-weight: bold;
      text-transform: uppercase;
      margin-top: 1em;
    }
    p {
      margin: 0.5em 0;
    }
    .view-empty {
      margin: 3em 0;
    }
  }
  .openlayers-views-map {
    margin: 3em 0;
    width: 100%;
    height: 18em;
    @include media($breakpoint-min-mobile-wide) {
      height: 23em;
    }
    @include media(new-breakpoint(min-width 35rem)) {
      height: 29em;
    }
    @include media($breakpoint-min-medium) {
      height: 33em;
    }
    @include media($breakpoint-min-medium) {
      height: 36em;
    }
    .openlayers-container {
      width: 100%;
      height: 100%;
      .openlayers-map {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// Find Justice search block/pane.
.pane-jn-find-justice-find-justice {
  text-align: center;
  min-height: 16em;
  padding: 1em;
  margin: 0 -1rem;
  @include media($breakpoint-min-mobile-wide) {
    margin: 0 -2rem;
  }
  @include media($breakpoint-min-medium) {
    min-height: 14em;
  }
  background-image: url(../images/bg-gray-map-crop.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  h2.pane-title {
    font-size: 1.5em;
    margin: 0;
    padding: 2em 0 0;
    @include media($breakpoint-min-medium) {
      font-size: 2.4em;
      padding-top: 1em;
    }
  }
  .form-item-search {
    display: inline-block;
    label {
      display: none;
    }
    input {
      background-color: transparent;
      font-size: 1.3em;
      width: 13em;
      height: 2rem;
      border-right: 2.5rem solid white;
      @include media($breakpoint-min-medium) {
        font-size: 1em;
        width: 20em;
      }
      @include placeholder() {
        color: #c0c0c0;
      }
    }
  }
  .form-submit {
    display: inline-block;
    height: 1.9rem;
    position: relative;
    left: -6px;
    top: -4px;
    margin-left: -2.2rem;
    color: $color-highlight-main;
    background-color: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-indent: -9999px;
    width: 2rem;
    background-image: url(../images/location_icon_yellow.png);
    background-repeat: no-repeat;
    background-position: center;
    @include media($breakpoint-min-medium) {
      left: -7px;
    }
    @include media($breakpoint-min-xlarge) {
      left: -8px;
    }
  }
}