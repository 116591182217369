/* @file
 * Styles for footer-bottom on Justice Network site.
 */
.footer-bottom {
  width: 100%;
  a {
    color: inherit;
    font-weight: inherit;
    text-decoration: none;
    &:hover {
      color: white;
    }
  }
  .container-wrapper {
    background-color: $background-light-grey;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    .content {
      font-size: 0.35rem;
      font-weight: 400;
      letter-spacing: 0.04em;
      @include media($breakpoint-min-mobile-wide) {
        font-size: 0.4rem;
        letter-spacing: 0.05em;
      }
      @include media($breakpoint-min-medium) {
        font-size: 0.6rem;
        font-weight: 200;
        letter-spacing: 0.1em;
      }
      text-transform: uppercase;
      color: #434343;
      .field {
        display: inline-block;
        .field-items {
          display: inline-block;
          .field-item {
            display: inline-block;
          }
        }
      }
      .field-name-field-linkinfo-links .field-item {
        margin-right: 1.1em;
        &::after {
          content : "|"
        }
        a {
          margin-right: 1.2em;
        }
      }
    }
  }
}